<template>
  <div v-if="showModuloForm === false">
    <MarketingModules></MarketingModules>
  </div>
  <div class="page" v-else-if="showModuloForm === true">
    <Panel lineColor="var(--primary-color)">
      <template v-slot:options>
        <FormButtonGroup align="end" spaced>
          <FormButton text="Novo link de ação" type="primario" @click="onNew" />
        </FormButtonGroup>
      </template>
      <DataTable
        :headers="headers"
        :endpoint="endpoint"
        :sortDesc="false"
        ref="datatable"
        sortBy="nome"
        search
        @click="onClick"
      >
        <template v-slot:ativo="{ col }">
          <Chip v-if="col" color="green" text="Sim" :small="true" />
          <Chip v-if="!col" color="cyan" text="Não" :small="true" />
        </template>
        <template v-slot:tipo_acao="{ col }">
          {{ labelAcao[col] }}
        </template>
        <template v-slot:link="{ row }">
          <FormButton
            text="Copiar"
            type="auxiliar"
            small
            @click="
              copyToClipboard(
                `http://${row.fornecedor_dominio}/${row.fornecedor_slug}/acao/${row.slug}`
              )
            "
          />
        </template>
        <template v-slot:idlinkacao="{ row }">
          <FormButtonMenu
            icon
            :options="tableOptions"
            @click="op => selectOption(row, op)"
          >
            <v-icon size="15">fas fa-ellipsis-v</v-icon>
          </FormButtonMenu>
        </template>
      </DataTable>
    </Panel>
    <transition name="slide-popup" mode="out-in">
      <router-view class="view" @close="onClose"></router-view>
    </transition>
  </div>
</template>

<script>
import DataTable from '@/components/DataTable'
import FormButton from '@/components/form/FormButton'
import FormButtonMenu from '@/components/form/FormButtonMenu'
import Panel from '@/components/Panel'
import Chip from '@/components/Chip'
import { mapGetters } from 'vuex'
import { LINK_ACAO_REMOVE } from '@/store/actions/link-acao'
import FormButtonGroup from '@/components/form/FormButtonGroup'
import MarketingModules from '@/components/MarketingModules'
import { ModulesEnum } from '@/store/actions/modulesEnum'

export default {
  name: 'LinkAcoes',
  components: {
    Chip,
    DataTable,
    Panel,
    FormButton,
    FormButtonMenu,
    FormButtonGroup,
    MarketingModules
  },
  data: () => ({
    headers: [
      {
        text: 'Nome',
        value: 'nome'
      },
      {
        text: 'Ativo',
        value: 'ativo'
      },
      {
        text: 'Ação',
        value: 'tipo_acao'
      },
      {
        text: 'Copiar',
        value: 'link',
        align: 'center',
        clickable: false
      },
      {
        text: 'Opções',
        value: 'idlinkacao',
        align: 'center',
        clickable: false
      }
    ],
    tableOptions: [
      { text: 'Editar', value: 'update' },
      { text: 'Deletar', value: 'delete' }
    ],
    labelAcao: {
      CARRINHO_COMPRAS: 'Carrinho de compras'
    },
    modulosForm: [
      ModulesEnum.ORCAMENTO_WEB,
      ModulesEnum.ORCAMENTO_APP,
      ModulesEnum.WEB_COMMERCE,
      ModulesEnum.COMMERCE_APP
    ]
  }),
  computed: {
    ...mapGetters(['getFornecedorId', 'moduloFornecedorAtivo']),
    endpoint() {
      return `/api/v4/link-acao/${this.getFornecedorId}/datatable`
    },
    showModuloForm() {
      return this.moduloFornecedorAtivo(this.modulosForm)
    }
  },
  methods: {
    selectOption(item, option) {
      switch (option.value) {
        case 'update':
          return this.onUpdate(item)
        case 'delete':
          return this.onDelete(item)
      }
    },
    onNew() {
      this.$router.push('link-acao/novo')
    },
    onClose() {
      this.$refs.datatable && this.$refs.datatable.reload()
    },
    onClick(item) {
      this.onUpdate(item)
    },
    onDelete(item) {
      const options = {
        text: 'Essa ação é irreversível',
        title: `Você confirma a exclusão da ação ${item.nome}?`
      }
      this.confirmSwal(options, () => this.delete(item))
    },
    onUpdate(item) {
      this.$router.push(`link-acao/${item.idlinkacao}`)
    },
    delete(item) {
      this.$store
        .dispatch(LINK_ACAO_REMOVE, { idlinkacao: item.idlinkacao })
        .then(() => {
          this.$vueOnToast.pop('success', 'Link de ação removida com sucesso')
          this.$refs.datatable.reload()
        })
    },
    copyToClipboard(text) {
      const tempTextArea = document.createElement('textarea')
      tempTextArea.value = text
      document.body.appendChild(tempTextArea)
      tempTextArea.select()
      document.execCommand('copy')
      document.body.removeChild(tempTextArea)
    }
  }
}
</script>
