var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showModuloForm === false)?_c('div',[_c('MarketingModules')],1):(_vm.showModuloForm === true)?_c('div',{staticClass:"page"},[_c('Panel',{attrs:{"lineColor":"var(--primary-color)"},scopedSlots:_vm._u([{key:"options",fn:function(){return [_c('FormButtonGroup',{attrs:{"align":"end","spaced":""}},[_c('FormButton',{attrs:{"text":"Novo link de ação","type":"primario"},on:{"click":_vm.onNew}})],1)]},proxy:true}])},[_c('DataTable',{ref:"datatable",attrs:{"headers":_vm.headers,"endpoint":_vm.endpoint,"sortDesc":false,"sortBy":"nome","search":""},on:{"click":_vm.onClick},scopedSlots:_vm._u([{key:"ativo",fn:function(ref){
var col = ref.col;
return [(col)?_c('Chip',{attrs:{"color":"green","text":"Sim","small":true}}):_vm._e(),(!col)?_c('Chip',{attrs:{"color":"cyan","text":"Não","small":true}}):_vm._e()]}},{key:"tipo_acao",fn:function(ref){
var col = ref.col;
return [_vm._v(" "+_vm._s(_vm.labelAcao[col])+" ")]}},{key:"link",fn:function(ref){
var row = ref.row;
return [_c('FormButton',{attrs:{"text":"Copiar","type":"auxiliar","small":""},on:{"click":function($event){return _vm.copyToClipboard(
              ("http://" + (row.fornecedor_dominio) + "/" + (row.fornecedor_slug) + "/acao/" + (row.slug))
            )}}})]}},{key:"idlinkacao",fn:function(ref){
            var row = ref.row;
return [_c('FormButtonMenu',{attrs:{"icon":"","options":_vm.tableOptions},on:{"click":function (op) { return _vm.selectOption(row, op); }}},[_c('v-icon',{attrs:{"size":"15"}},[_vm._v("fas fa-ellipsis-v")])],1)]}}])})],1),_c('transition',{attrs:{"name":"slide-popup","mode":"out-in"}},[_c('router-view',{staticClass:"view",on:{"close":_vm.onClose}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }